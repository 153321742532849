import moment from "moment";

export const acceptedMime = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/pdf",
  "text/csv"
];

export const cdnPath = "cdn";
export const tempVideoPath = "tempVideos";
export const videoPath = "videos";
export const thumbnailPath = "thumbnail";
export const documentPath = "documents";

export const videoCollectionPath = "videocollection";
export const courseCollectionPath = "coursecollection";

export const userCollectionPath = "users";
export const groupsCollectionPath = "groups";

export const quizCollectionPath = "quiz";
export const questionCollectionPath = "question";

export const groupDelete = "groupDelete";
export const userDelete = "userDelete";

export const groupManageUserTab = "userTab";
export const groupManageCourseTab = "groupTab";

export const uploadedVideoStateInProgress = "Uploading";
export const uploadedVideoStateUploadedToTemp = "UploadedInTemp";
export const uploadedVideoStatePublished = "Published";

export const extension = [
  { exstension: ".docx", src: "assets/video-detail/worddocument.png" },
  { exstension: ".docs", src: "assets/video-detail/worddocument.png" },
  { exstension: ".pptx", src: "assets/video-detail/ppt.png" },
  { exstension: ".xlsx", src: "assets/video-detail/excel.png" },
  { exstension: ".pdf", src: "assets/video-detail/pdf.png" },
  { exstension: ".csv", src: "assets/video-detail/excel.png" }
];

export const videoSetting = {
  configuration: "VideoPrivate",
  default: "Private"
};

export const numberFormater = number => {
  number = number < 10 ? `0${number}` : number;
  return number;
};

export const multipleChoice = "Multiple Choice";
export const singleChoice = "Single Choice";

export const Sort = {
  Asc: "Asc",
  Desc: "Desc"
};

export const CourseType = {
  Offline: 1,
  Online: 2
};

export const createDateTime = (date, time) => {
  return moment(`${date} ${time}`, "YYYY-MM-DDTHH:mm:ssZ").format();
};

export const currenCustomtDate = date => {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

export const currentTime = () => {
  return moment().format("HH:mm");
};

export function convertTo24Hour(timeString) {
  const parsedTime = moment(timeString, ["h:mm A", "hh:mm A"]);
  // Format parsed time in 24-hour format
  const formattedTime = parsedTime.format("HH:mm");
  return formattedTime;
}

export const dialogType = {
  modelClose: "modelClose",
  sessionCancel: "sessionClose"
};

export const validationLimits = {
  sessionNameMax: 50,
  sessionDescriptionMax: 1000,
  courseNameMax: 50,
  courseDescriptionMax: 1000,
  videoTitleMax: 50,
  videoDescriptionMax: 1000
};

export const tenantCourseConfig = {
  VideoPrivate: "Video",
  CoursePrivate: "Course"
};

export const errorTitles = {
  titleEmptyError: "Title should not be empty",
  titleExceedError: "Title should between 1 and 50 characters",
  descriptionError: "Description should not be empty",
  descriptionExceedError: "Description should between 1 and 1000 characters"
};